import {
  combineValidators,
  composeValidators,
  createValidator
} from 'revalidate';
import {
  isArray,
  isFieldOneOf,
  isFieldRequired,
  isHexString,
  isObject,
  isNonNegativeInteger,
  isNonNegativeNumber,
  isPositiveNumber,
  isRequiredBoolean,
  isRequiredStringIf,
  isString,
  isValueUnique
} from 'Utils/validators';
import {
  BANK_TRANSFER,
  SCALE,
  redemptionMethodTypes
} from 'Enums/RedemptionMethodTypes';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import { translationStringsValidator, translationObjectValidator } from './translationValidator';

const isRequiredStringIfEnabled = (field) => isRequiredStringIf(
  field,
  values => {
    return values.enabled;
  }
);

const feeValidator = (name, fee, missingFees, invalidFees) => {
  const fixedFee = fee?.fixed;
  const variableFee = fee?.variable;
  if (fixedFee === undefined) {
    missingFees.push(`${name}.fixed`);
  } else if (typeof fixedFee !== 'number' || fixedFee < 0) {
    invalidFees.push(`${name}.fixed`);
  } else {
    const fixedFeeScale = fixedFee.toString().split(".")[1]?.length || 0;
    if (fixedFeeScale > SCALE[BANK_TRANSFER]) {
      invalidFees.push(`${name}.fixed`);
    }
  }

  if (variableFee === undefined) {
    missingFees.push(`${name}.variable`);
  } else if (typeof variableFee !== 'number' || variableFee < 0) {
    invalidFees.push(`${name}.variable`);
  } else {
    const variableFeeScale = variableFee.toString().split(".")[1]?.length || 0;
    if (variableFeeScale > SCALE[BANK_TRANSFER]) {
      invalidFees.push(`${name}.variable`);
    }
  }
};

const feesValidator = createValidator(
  () => (redemptionMethod) => {
    const { id, name, userGets } = redemptionMethod;
    const missingFees = [];
    const invalidFees = [];
    const generalFees = ["issuerFee", "methodFee", "fallbackCountryFee"];
    generalFees.forEach(name => feeValidator(
      name,
      userGets?.feesWithheld?.[name],
      missingFees,
      invalidFees
    ));
    const countryFees = userGets?.feesWithheld?.countryFees;
    if (typeof countryFees === "object") {
      const countries = Object.keys(countryFees);
      countries.forEach(country => feeValidator(
        `countryFees.${country}`,
        countryFees[country],
        missingFees,
        invalidFees
      ));
    }
    if (missingFees.length) {
      return `${i18nextTranslateDynamically(
        i18nextKeys.validatorRedemption,
        { id, name }
      )
        }: ${i18nextTranslateDynamically(
          i18nextKeys.validatorRedemptionFeesMissing,
          { fees: missingFees.join(", ") }
        )
        }`;
    }
    if (invalidFees.length) {
      return `${i18nextTranslateDynamically(
        i18nextKeys.validatorRedemption,
        { id, name }
      )
        }: ${i18nextTranslateDynamically(
          i18nextKeys.validatorRedemptionFeesInvalid,
          { fees: invalidFees.join(", ") }
        )
        }`;
    }
  },
  () => { }
);

const limitsValidator = createValidator(
  () => (redemptionMethod) => {
    const { id, name, type, userGets, userPays } = redemptionMethod;
    if (!userGets?.limits && !userPays?.limits) {
      return `${i18nextTranslateDynamically(
        i18nextKeys.validatorRedemption,
        { id, name }
      )}: ${i18nextTranslate(i18nextKeys.validatorRedemptionLimits)}`;
    }
    if (userGets?.limits && userPays?.limits) {
      return `${i18nextTranslateDynamically(
        i18nextKeys.validatorRedemption,
        { id, name }
      )
        }: ${i18nextTranslate(i18nextKeys.validatorRedemptionLimitsDuplicate)}`;
    }
    const {
      minNetAmount,
      maxNetAmount,
      incrementNetAmount
    } = userGets?.limits || userPays?.limits;
    if (minNetAmount && maxNetAmount && minNetAmount > maxNetAmount) {
      let minNetAmountString = minNetAmount;
      let maxNetAmountString = maxNetAmount;
      if (userGets?.limits && userGets?.uom) {
        minNetAmountString = `${minNetAmount} ${userGets?.uom}`;
        maxNetAmountString = `${maxNetAmount} ${userGets?.uom}`;
      }
      return `${i18nextTranslateDynamically(
        i18nextKeys.validatorRedemption,
        { id, name }
      )}: ${i18nextTranslateDynamically(
        i18nextKeys.validatorRedemptionMaxAmount,
        {
          minNetAmount: minNetAmountString,
          maxNetAmount: maxNetAmountString
        }
      )}`;
    }
    const scale = SCALE[type];
    if (userGets.limits && scale) {
      const minScale = minNetAmount.toString().split(".")[1]?.length || 0;
      const maxScale = maxNetAmount.toString().split(".")[1]?.length || 0;
      const incrementScale = incrementNetAmount.toString().split(".")[1]?.length || 0;
      if (minScale > scale || maxScale > scale || incrementScale > scale) {
        return `${i18nextTranslateDynamically(
          i18nextKeys.validatorRedemption,
          { id, name }
        )}: ${i18nextTranslateDynamically(
          i18nextKeys.validatorRedemptionLimitsScale,
          { type, scale }
        )}`;
      }
    }
  },
  () => {}
);

const limitsValuesValidator = () => ({
  minNetAmount: isNonNegativeNumber('minNetAmount'),
  maxNetAmount: isPositiveNumber('maxNetAmount'),
  incrementNetAmount: isNonNegativeNumber('incrementNetAmount')
});

const unitPriceValidator = ({ field }, { userGets }) => {
  const redemptionMethodProps = Object.keys(userGets);
  const isUnitPriceSet = redemptionMethodProps.includes("unitPrice");
  const isPriceSourceSet = redemptionMethodProps.includes("priceSource");
  if (isUnitPriceSet && isPriceSourceSet) {
    return i18nextTranslateDynamically(i18nextKeys.validatorRelatedConflict, {
      firstProp: "unitPrice",
      secondProp: "priceSource"
    });
  }
  if (!isUnitPriceSet && !isPriceSourceSet) {
    return i18nextTranslateDynamically(i18nextKeys.validatorRelatedMissing, {
      firstProp: "unitPrice",
      secondProp: "priceSource"
    });
  }
};

const userGetsValidator = (method) => ({
  uom: isRequiredStringIfEnabled('uom'),
  ...!method.userGets.priceSource && {
    unitPrice: isPositiveNumber('unitPrice')
  },
  ...!method.userGets.unitPrice && {
    priceSource: isString("priceSource")
  },
  feesWithheld: {
    countryFees: isObject('countryFees')
  },
  ...!method.userPays.limits && {
    limits: limitsValuesValidator()
  }
});

const userPaysValidator = (method) => ({
  targetAddress: isHexString('targetAddress'),
  uniqueAssetId: isHexString('uniqueAssetId'),
  ...!method.userGets.limits && {
    limits: limitsValuesValidator()
  }
});

const additionalInfoValidator = () => combineValidators({
  enabled: isRequiredBoolean('enabled'),
  name: isRequiredStringIfEnabled('name'),
  description: isString('description')
});

const translationValidator = ({ name, description, customTermsSummaryScreen }, lang) => {
  if (!name || typeof name !== "string") {
    return i18nextTranslate(
      i18nextKeys.helperValidatorString,
      { field: `${lang}.name` }
    );
  }
  if (!description || typeof description !== "string") {
    return i18nextTranslate(
      i18nextKeys.helperValidatorString,
      { field: `${lang}.description` }
    );
  }
  if (!Array.isArray(customTermsSummaryScreen)) {
    return i18nextTranslate(i18nextKeys.helperValidatorArray, {
      field: `${lang}.customTermsSummaryScreen`
    });
  }
  if (customTermsSummaryScreen.length) {
    for (let i = 0; i < customTermsSummaryScreen.length; i++) {
      if (!customTermsSummaryScreen[i] || typeof customTermsSummaryScreen[i] !== "string") {
        return i18nextTranslate(
          i18nextKeys.helperValidatorString,
          { field: `${lang}.customTermsSummaryScreen.${i}` }
        );
      }
    }
  }
};

const redemptionTranslationValidator = (defaultLanguage, required) => {
  return (field, values) => {
    const languages = Object.keys(values);
    if (!languages.length) {
      return i18nextTranslate(
        i18nextKeys.validatorTranslationLanguageDefault,
        { defaultLanguage }
      );
    }
    const error = translationObjectValidator(values, defaultLanguage, required, translationValidator);
    return error;
  }
}

const redemptionMethodValidator = (defaultLanguage) => ({ field }, method) =>
  combineValidators({
    type: composeValidators(
      isFieldRequired,
      isFieldOneOf(redemptionMethodTypes.map(type => type.toLowerCase()))('type')
    )('type'),
    enabled: isRequiredBoolean('enabled'),
    kycTier: isNonNegativeInteger('kycTier'),
    id: isRequiredStringIfEnabled('id'),
    userGets: userGetsValidator(method),
    userPays: userPaysValidator(method),
    translations: composeValidators(
      isObject(""),
      redemptionTranslationValidator(defaultLanguage, method.enabled)
    )("translations")
  })(method);

const redemptionMethodArrayValidator = (defaultLanguage) => (field, methods) =>
  methods.map(composeValidators(
    limitsValidator,
    feesValidator,
    unitPriceValidator,
    redemptionMethodValidator(defaultLanguage)
  )('redemptionMethod'));

const redemptionConfigValidator = (defaultLanguage) => ({
  menuItemName: composeValidators(
    isObject(''),
    translationStringsValidator(defaultLanguage)
  )('menuItemName'),
  additionalInfoRedemptionRequest: additionalInfoValidator(),
  methodSelectionDescription: composeValidators(
    isObject(''),
    translationStringsValidator(defaultLanguage)
  )('methodSelectionDescription'),
  redemptionMethods: composeValidators(
    isArray,
    isValueUnique(
      i18nextTranslate(i18nextKeys.validatorRedemptionUniqueMethodIds),
      'id'
    ),
    redemptionMethodArrayValidator(defaultLanguage)
  )('redemptionMethods')
});

export default redemptionConfigValidator;
