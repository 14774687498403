import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle
} from 'react';
import api from 'Api';
import { DYNAMIC_PRICE_POLLING_INTERVAL } from 'Helpers/assets';
import { getTranslation } from 'Helpers/translations';
import useConfigSettings from 'Hooks/useConfigSettings';
import { LangContext } from 'States/lang/langState';
import { UiContext } from 'States/ui/uiState';
import { objectArrayToObject } from 'Utils/utils';
import { useFormatNumber } from 'Utils/formatNumber';
import useHandleError from 'Utils/handleError';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import Text from 'Components/shared/Text';
import { PRICE_SOURCE } from 'Enums';
import { REDEMPTION_AMOUNT_FIELD } from './PayReceive';
import FeeBlock from './FeeBlock';

const Amount = ({
  formattedAmount,
  units,
  label,
  assetName,
  dataQa,
  style = {},
}) => (
  <div
    className="flex justify-between border-solid rounded border-2 border-color-5 px-10 py-8"
    style={style}
  >
    <div className='flex gap-2'>
      <Text dataQa={`${dataQa}-label`}>
        {label}
      </Text>
      {!!assetName && (
        <Text
          textStyle='p2'
          dataQa={`${dataQa}-asset`}
        >
          {assetName}
        </Text>
      )}
    </div>
    
    <Text
      textStyle='p2'
      dataQa={`${dataQa}-value`}
    >
      {`${formattedAmount} ${units}`}
    </Text>
  </div>
);

const PayReceiveSummary = forwardRef(({ amountBreakdown, redemptionMethod, beneficiaryCountry }, ref) => {
  const { breakpoints: { md } } = useContext(UiContext);
  const { lang } = useContext(LangContext);
  const formatNumber = useFormatNumber();
  const handleError = useHandleError();
  const pollingInterval = useRef(null);
  const [updatedAmounts, setUpdatedAmounts] = useState(null);
  const { data: defaultLanguageCode } = useConfigSettings.query({
    select: useCallback(({ Languages }) => {
      const language = Languages.find(({ IsDefault }) => IsDefault)
      return language?.Code
    }, [])
  });

  const amounts = updatedAmounts || amountBreakdown;

  const {
    UserGets: {
      Uom: payoutUnits,
      PriceSource
    },
    UserPays: {
      UnitOfMeasureCode: redeemUnits,
      CurrencyCode: redeemCurrency,
      Name: redeemAssetName,
      Translations: redeemAssetTranslations
    }
  } = redemptionMethod;

  useImperativeHandle(ref, () => ({
    getUpdatedAmounts: () => updatedAmounts
  }), [updatedAmounts])

  useEffect(() => {
    if (PriceSource && PriceSource !== PRICE_SOURCE.none && !pollingInterval.current) {
      pollingInterval.current = setInterval(async () => {
        try {
          let { Calculations } = await api.Redemptions.getAmountsAndFees({
            methodId: redemptionMethod.Id,
            country: beneficiaryCountry,
            fieldName: REDEMPTION_AMOUNT_FIELD.pay,
            amount: amounts.GrossAmount.AssetUnitsString
          });
          Calculations[0].Fees = objectArrayToObject(Calculations[0].Fees, "Type");
          if (Calculations.length > 1) {
            Calculations[1].Fees = objectArrayToObject(Calculations[1].Fees, "Type");
            Calculations.sort((a, b) =>
              Big(a.GrossAmount.AssetUnitsString) - Big(b.GrossAmount.AssetUnitsString)
            );
          };
          setUpdatedAmounts(Calculations[0]);
        } catch (error) {
          handleError({ error });
        }
      }, DYNAMIC_PRICE_POLLING_INTERVAL);
    }
    return () => {
      if (pollingInterval.current) {
        clearInterval(pollingInterval.current);
      }
    }
  }, [PriceSource]);

  const translatedAssetName = useMemo(() =>
    getTranslation("Name", lang, defaultLanguageCode, redeemAssetTranslations) || redeemAssetName,
    [lang]
  );

  return (
    <>
      <div className="flex flex-col md:flex-row justify-between">
        <Amount
          formattedAmount={
            formatNumber(amounts.GrossAmount.AssetUnitsString)
          }
          units={redeemUnits || redeemCurrency}
          label={i18nextTranslate(i18nextKeys.redemptionPayReceiveYouPay)}
          assetName={translatedAssetName}
          style={{ width: md ? '45%' : '100%' }}
          dataQa="pay"
        />
        {!md && (
          <FeeBlock
            calculation={amounts}
            payoutUnits={payoutUnits}
            redeemUnits={redeemUnits || redeemCurrency}
            className="w-full mt-24 mb-32"
          />
        )}
        <Amount
          formattedAmount={
            formatNumber(amounts.NetAmount.PayoutUnitsString)
          }
          units={payoutUnits}
          label={i18nextTranslate(i18nextKeys.redemptionPayReceiveYouReceive)}
          style={{ width: md ? '45%' : '100%' }}
          dataQa="receive"
        />
      </div>
      {md && (
        <FeeBlock
          calculation={amounts}
          payoutUnits={payoutUnits}
          redeemUnits={redeemUnits || redeemCurrency}
          style={{ width: '45%' }}
        />
      )}
    </>
  );
});

export default PayReceiveSummary;
