import { createValidator } from 'revalidate';
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";

const translationStringsValidator = (defaultLanguage, required = false) => createValidator(
  () => (translation) => {
    const translations = Object.entries(translation);
    let isAnyTranslationAvailable = false;
    let isDefaultTranslationAvailable = false;
    for (const [lang, value] of translations) {
      if (value === "") {
        continue;
      }
      if (typeof value !== "string") {
        return i18nextTranslateDynamically(
          i18nextKeys.helperValidatorString,
          { field: `"${lang}" ${i18nextTranslate(i18nextKeys.configTranslationsField).toLowerCase()}` }
        );
      }
      isAnyTranslationAvailable = true;
      if (lang === defaultLanguage || isDefaultTranslationAvailable) {
        continue;
      }
      if (translation[defaultLanguage] && typeof translation[defaultLanguage] !== "string") {
        return i18nextTranslateDynamically(
          i18nextKeys.helperValidatorString,
          { field: `"${defaultLanguage}" ${i18nextTranslate(i18nextKeys.configTranslationsField).toLowerCase()}` }
        );
      }
      if (!translation[defaultLanguage]) {
        return i18nextTranslateDynamically(
          i18nextKeys.validatorTranslationLanguageDefault,
          { language: defaultLanguage }
        );
      }
      isDefaultTranslationAvailable = true;
    }
    if (required && !isAnyTranslationAvailable) {
      return i18nextTranslateDynamically(
        i18nextKeys.validatorTranslationLanguageDefault,
        { language: defaultLanguage }
      );
    }
  },
  () => {}
);

const translationObjectValidator = (
  translation,
  defaultLanguage,
  required = false,
  innerValidator = (value, lang, required) => {}
) => {
  const translations = Object.entries(translation);
  let isAnyTranslationAvailable = false;
  let isDefaultTranslationAvailable = false;
  for (const [lang, value] of translations) {
    if (typeof value === "object" && !Object.keys(value).length) {
      continue;
    }
    if (typeof value !== "object") {
      return i18nextTranslateDynamically(
        i18nextKeys.helperValidatorObject,
        { field: `"${lang}" ${i18nextTranslate(i18nextKeys.configTranslationsField).toLowerCase()}` }
      );
    }
    const innerValidationError = innerValidator(value, lang);
    if (innerValidationError) {
      return innerValidationError;
    }
    isAnyTranslationAvailable = true;
    if (lang === defaultLanguage || isDefaultTranslationAvailable) {
      continue;
    }
    if (translation[defaultLanguage] && typeof translation[defaultLanguage] !== "object") {
      return i18nextTranslateDynamically(
        i18nextKeys.helperValidatorObject,
        { field: `"${defaultLanguage}" ${i18nextTranslate(i18nextKeys.configTranslationsField).toLowerCase()}` }
      );
    }
    if (!translation[defaultLanguage]) {
      return i18nextTranslateDynamically(
        i18nextKeys.validatorTranslationLanguageDefault,
        { language: defaultLanguage }
      );
    }
    const defaultValidationError = innerValidator(translation[defaultLanguage], defaultLanguage);
    if (defaultValidationError) {
      return defaultValidationError;
    }
    isDefaultTranslationAvailable = true;
  }
  if (required && !isAnyTranslationAvailable) {
    return i18nextTranslateDynamically(
      i18nextKeys.validatorTranslationLanguageDefault,
      { language: defaultLanguage }
    );
  }
}

export { translationStringsValidator, translationObjectValidator };
