import React from 'react';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import Text from 'Components/shared/Text';
import FeatureConfig from './FeatureConfig';
import MobileApplicationConfig from './MobileApplicationConfig';
import AdminConfig from './AdminConfig';
import LanguagesAndCurrenciesConfig from './LanguagesAndCurrencies/LanguagesAndCurrenciesConfig';
import EmailNotifications from './EmailNotifications';

const TenantConfig = ({
  generalSettingsInnerRef,
  languagesAndCurrenciesInnerRef,
}) => {
  return (
    <div className="px-32 py-24">
      <Text
        textStyle="h2"
        className="mb-24 xxl:mb-32"
        style={{
          scrollMargin: 60
        }}
        dataQa="general-title"
        reference={generalSettingsInnerRef}
        uppercase
      >
        {i18nextTranslate(i18nextKeys.tenantSettingsGeneralSettingsTitle)}
      </Text>
      <FeatureConfig />
      <AdminConfig />
      <MobileApplicationConfig />
      <hr className="border border-color-5 -mx-32 my-32 xxl:my-40" />
      <LanguagesAndCurrenciesConfig
        titleRef={languagesAndCurrenciesInnerRef}
      />
      <hr className="border border-color-5 -mx-32 my-32 xxl:my-40" />
      <EmailNotifications />
    </div>
  );
};

export default TenantConfig;
