import {
  combineValidators,
  composeValidators,
  createValidator
} from 'revalidate';
import {
  decimalPlacesValidator,
  isArray,
  isBoolean,
  isHexString,
  isNonNegativeInteger,
  isObject,
  isPositiveNumber,
  isPositiveNumberOrNumericString,
  isRequiredString,
  isString,
  isValueUnique,
  hasLengthGreaterThanIf
} from 'Utils/validators';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import { translationObjectValidator } from "./translationValidator";

const priceValidator = ({ field }, asset) => {
  const assetProps = Object.keys(asset);
  const isPriceSet = assetProps.includes("price");
  const isPriceSourceSet = assetProps.includes("priceSource");
  if (isPriceSet && isPriceSourceSet) {
    return i18nextTranslateDynamically(i18nextKeys.validatorRelatedConflict, {
      firstProp: "price",
      secondProp: "priceSource"
    });
  }
  if (!isPriceSet && !isPriceSourceSet) {
    return i18nextTranslateDynamically(i18nextKeys.validatorRelatedMissing, {
      firstProp: "price",
      secondProp: "priceSource"
    });
  }
};

const featuredAssetValidator = (
  isFeaturedAssetEnabled,
  restrictedMode
) => createValidator(
  () => (assets) => {
    if (!isFeaturedAssetEnabled) {
      return;
    }
    let featuredCount = 0;
    for (const asset of assets) {
      if (typeof asset.isFeaturedAsset === "boolean"
        && asset.isFeaturedAsset) {
        featuredCount += 1;
        if (!restrictedMode && featuredCount > 1) {
          return i18nextTranslate(i18nextKeys.validatorIsFeaturedAssetSingleAsset);
        }
      }
    }
    if (!featuredCount) {
      return i18nextTranslate(i18nextKeys.validatorIsFeaturedAssetRequired);
    } 
    return;
  },
  () => {}
);

const customPurchaseSettingsValidator = ({ customPurchaseSettings }, lang) => {
  let fieldName = ""
  if (!customPurchaseSettings.sidebarLink
    || typeof customPurchaseSettings.sidebarLink !== "string"
  ) {
    fieldName = "sidebarLink";
  }
  else if (!customPurchaseSettings.customHtmlSourcePath
    || typeof customPurchaseSettings.customHtmlSourcePath !== "string"
  ) {
    fieldName = "customHtmlSourcePath";
  }
  else if (!customPurchaseSettings.customHtmlSourcePathLoggedIn
    || typeof customPurchaseSettings.customHtmlSourcePathLoggedIn !== "string"
  ) {
    fieldName = "customHtmlSourcePathLoggedIn";
  }
  if (fieldName) {
    return i18nextTranslate(
      i18nextKeys.helperValidatorString,
      { field: `${lang}.customPurchaseSettings.${fieldName}` }
    );
  }
};

const assetTranslationValidator = (isFeaturedAssetEnabled, defaultLanguage) => {
  return (field, values) => {
    if (isFeaturedAssetEnabled || Object.keys(values).length) {
      const error = translationObjectValidator(values, defaultLanguage, true, customPurchaseSettingsValidator);
      return error;
    }
  }
}

export const assetValidator = (isFeaturedAssetEnabled, defaultLanguage) => {
  return ({ field }, asset) => {

    return combineValidators({
      kycTier: isNonNegativeInteger('kycTier'),
      icon: isRequiredString('icon'),
      issuerAddress: isHexString('issuerAddress'),
      uniqueAssetId: isHexString('uniqueAssetId'),
      maxAmount: isPositiveNumber('maxAmount'),
      ...!asset.priceSource && {
        price: composeValidators(
          isPositiveNumberOrNumericString('price'),
          decimalPlacesValidator(2)
        )('price')
      },
      ...!asset.price && {
        priceSource: isString("priceSource")
      },
      isFeaturedAsset: isBoolean('isFeaturedAsset'),
      translations: composeValidators(
        isObject(""),
        assetTranslationValidator(
          isFeaturedAssetEnabled && asset.isFeaturedAsset,
          defaultLanguage
        )
      )('customPurchaseSettings')
    })(asset);
  }
}

const assetArrayValidator = (isFeaturedAssetEnabled, defaultLanguage) =>
  (field, assets) =>
    assets.map(composeValidators(
      priceValidator,
      assetValidator(isFeaturedAssetEnabled, defaultLanguage)
    )('asset'));

const assetsValidator = (
  field,
  isFeaturedAssetEnabled,
  isPurchaseEnabled,
  restrictedMode,
  defaultLanguage
) =>
  composeValidators(
    isArray(field),
    hasLengthGreaterThanIf(
      0, isPurchaseEnabled, i18nextKeys.validatorAssetsRequired
    )('assets'),
    featuredAssetValidator(isFeaturedAssetEnabled, restrictedMode),
    isValueUnique(
      i18nextTranslate(i18nextKeys.validatorAssetsUniqueAssetIds),
      'uniqueAssetId'
    ),
    assetArrayValidator(isFeaturedAssetEnabled, defaultLanguage)
  )({ field });

export default assetsValidator;
