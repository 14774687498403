import { composeValidators } from "revalidate";
import { isObject } from "Utils/validators";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import { translationStringsValidator } from "./translationValidator";

const customTermsArrayValidator = (defaultLanguage) => ({ field }, terms) => {
  if (terms.length) {
    return terms.map(composeValidators(
      isObject(""),
      translationStringsValidator(defaultLanguage)
    )("term"));
  } else {
    return i18nextTranslateDynamically(i18nextKeys.helperValidatorArrayEmpty, { field });
  }
};

export default customTermsArrayValidator;
