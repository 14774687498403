import React, { useCallback, useEffect, useState, useContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAmplitude } from 'react-amplitude-hooks';
import Fade from 'Components/shared/Fade';
import { DefaultCard } from 'Components/shared/cards';
import {
  CryptoSymbol,
  BankTransferSymbol,
  CreditCardSymbol,
} from 'Components/shared/symbols';
import { LangContext } from 'States/lang/langState';
import { UiContext } from 'States/ui/uiState';
import { STORAGE_KEYS } from 'Enums';
import { ORDER_STATUS } from 'Enums/OrderStatus';
import { ROUTES } from 'Router/Routes';
import useHandleError from 'Utils/handleError';
import { getTranslation } from 'Helpers/translations';
import PaymentMethodEnum from 'Enums/PaymentMethod';
import useConfigSettings from 'Hooks/useConfigSettings';
import useKycTierCheck from 'Hooks/useKycTierCheck';
import usePaymentMethods from 'Hooks/usePaymentMethods';
import { useFormatNumber } from 'Utils/formatNumber';
import api from 'Api';
import BankTransfer from './PaymentMethods/BankTransfer';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import Text from 'Components/shared/Text';
import Warning from 'Components/shared/Warning';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import CheckoutTimer from '../CheckoutTimer';
import CheckoutPaymentButtonGroup from './CheckoutPaymentButtonGroup';

const CheckoutPayment = () => {
  const handleError = useHandleError();
  const history = useHistory();
  const formatNumber = useFormatNumber();
  const { lang } = useContext(LangContext);
  const {
    breakpoints: { md, xxl },
  } = useContext(UiContext);
  const location = useLocation();
  const sessionData = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.order));
  const order = location?.state?.order || sessionData;
  const orderId = order?.Id;
  const [loading, setLoading] = useState(false);
  const [showBankTransfer, setShowBankTransfer] = useState(false);
  const [showLimitsWarning, setShowLimitsWarning] = useState(false);
  const { logEvent } = useAmplitude();

  useEffect(() => {
    if (!order) {
      history.replace(ROUTES.checkoutCollectionMethod);
      return;
    }
    if (sessionData) {
      api.PurchaseOrder.updateDelta(orderId, {
        Status: ORDER_STATUS.Draft,
      }).catch((error) => {
        const message = i18nextTranslate(
          i18nextKeys.errorCartCheckoutPaymentUpdateStatusError
        );
        handleError({ error, message });
      });
    }
  }, []);

  const {
    isLoading: loadingSettings,
    data: defaultLanguage
  } = useConfigSettings.query({
    select: useCallback(
      ({ Languages }) => Languages.find(
        ({ IsDefault }) => IsDefault
      ), []
    )
  });

  const {
    isFetching: loadingPaymentMethods,
    data: paymentMethods
  } = usePaymentMethods.methodsQuery({
    refetchOnWindowFocus: false,
    select: useCallback(
      ({ value: paymentMethods }) => paymentMethods.sort(
        ({ Type: a }, { Type: b }) => PaymentMethodEnum.order[a] - PaymentMethodEnum.order[b]
      ),
      []
    ),
    onError: (error) => {
      handleError({ error });
    }
  });

  const bankTransferDetails = useMemo(() => {
    return paymentMethods?.length
      ? paymentMethods.find(({ Type }) => Type === PaymentMethodEnum.values.BankTransfer)
      : {}
    },
    [paymentMethods])

  const redirectToProvider = async (paymentMethod) => {
    try {
      logEvent('Payment type selected', {
        type: paymentMethod,
      });
      setLoading(true);
      const successRedirectUrl = `${window.location.origin
        }${ROUTES.checkoutPending}`;
      const failRedirectUrl = `${window.location.origin}${ROUTES.checkoutPaymentMethod}`;
      const { Link } = await api.PurchaseOrder.getPaymentLink({
        orderId,
        paymentMethod,
        successRedirectUrl,
        failRedirectUrl,
      });
      sessionStorage.setItem(STORAGE_KEYS.order, JSON.stringify(order));
      window.location.href = Link;
    } catch (error) {
      setLoading(false);
      const message = i18nextTranslate(
        i18nextKeys.errorCartCheckoutPaymentProviderLaunchError
      );
      handleError({ error, message });
    }
  };

  const paymentCardConfig = {
    [PaymentMethodEnum.values.BankTransfer]: {
      name: i18nextTranslate(i18nextKeys.commonBankTransfer),
      icon: <BankTransferSymbol size={md ? '100px' : '50px'} />,
      selectMethod: () => {
        logEvent('Payment type selected', {
          type: PaymentMethodEnum.values.BankTransfer
        });
        setShowBankTransfer(true);
      }
    },
    [PaymentMethodEnum.values.CreditCard]: {
      name: i18nextTranslate(i18nextKeys.commonCreditCard),
      icon: <CreditCardSymbol size={md ? '100px' : '50px'} />,
      selectMethod: () => redirectToProvider(PaymentMethodEnum.values.CreditCard)
    },
    [PaymentMethodEnum.values.CryptoCurrency]: {
      name: i18nextTranslate(i18nextKeys.commonCryptocurrencies),
      icon: <CryptoSymbol size={md ? '130' : '50'} />,
      selectMethod: () => redirectToProvider(PaymentMethodEnum.values.CryptoCurrency)
    }
  };

  return (
    <Fade show={true}>
      <>
        {loading || loadingPaymentMethods || loadingSettings ? (
          <div className="flex justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <Fade show={!loading}>
            {showBankTransfer ? (
              <BankTransfer
                order={order}
                paymentMethod={bankTransferDetails}
                defaultLanguage={defaultLanguage}
                goBack={() => setShowBankTransfer(false)}
              />
            ) : (
              <>
                <div
                  className="color-8 p-16 mb-32 bg-3 rounded"
                  style={{ gridColumn: '1/-1' }}
                >
                  <CheckoutTimer
                    startDateISO={order.ReceivedOn}
                    durationISO={order.timeouts.AbortAfter}
                    showTimer
                  />
                </div>
                <div
                  className='flex justify-center mb-20 md:mb-24'
                >
                  <div
                    className="flex flex-col gap-16"
                    style={{ maxWidth: xxl ? "700px" : "650px" }}
                  >
                    <Text textStyle="p2">
                      {i18nextTranslate(i18nextKeys.checkoutPaymentMethodSelect)}
                    </Text>
                    {showLimitsWarning && (
                      <Warning visibleText={i18nextTranslate(i18nextKeys.checkoutPaymentWarning)} />
                    )}
                  </div>
                </div>
                <div
                  data-qa="checkout-payment"
                  className="grid row-gap-32 col-gap-40 justify-center"
                  style={{
                    gridTemplateColumns: md
                      ? `repeat(auto-fit, ${xxl ? '206px' : '188px'})`
                      : 'repeat(auto-fit, 100%)',
                    gridAutoRows: '1fr',
                  }}
                >
                  {paymentMethods.map((paymentMethod) => {
                    if (!paymentMethod.IsEnabled) {
                      return null;
                    }
                    const { name, icon, selectMethod } = paymentCardConfig[paymentMethod.Type];
                    const isOverLimit = paymentMethod.MaxAmount !== null &&
                      order.AmountDue > paymentMethod.MaxAmount;
                    const { isUserTierSufficient } = useKycTierCheck(paymentMethod.KYCTier);
                    if (isUserTierSufficient && isOverLimit && !showLimitsWarning) {
                      setShowLimitsWarning(true);
                    }
                    return (
                      <DefaultCard
                        key={paymentMethod.Type}
                        dataQa={paymentMethod.Type}
                        name={name}
                        description={
                          isUserTierSufficient && isOverLimit
                            ? `${i18nextTranslate(i18nextKeys.checkoutPaymentMethodLimit)}: ${
                                formatNumber(paymentMethod.MaxAmount)
                              } ${order.AmountDueCurrency}`
                            : getTranslation(
                                "Description",
                                lang,
                                defaultLanguage.Code,
                                paymentMethod.Translations
                              )
                        }
                        svg={icon}
                        buttonGroup={() =>
                          CheckoutPaymentButtonGroup({
                            requiredTier: paymentMethod.KYCTier,
                            selectPaymentMethod: selectMethod,
                            disabled: isUserTierSufficient && isOverLimit
                          })
                        }
                      />
                    );
                  })}
                </div>
              </>
            )}
          </Fade>
        )}
      </>
    </Fade>
  );
};

export default CheckoutPayment;
