import React, { useState, useContext } from 'react';
import amplitude from 'amplitude-js';
import { ConfigContext } from 'States/config/configState';
import { Button } from 'Components/shared/buttons';
import CookieModal from 'Components/CookieNotice/CookieModal';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';

const CookieNotice = ({ margin = '' }) => {
  const [showCookieModal, setShowCookieModal] = useState(false);
  const { disableNonNecessaryCookies, enableNonNecessaryCookies } = useContext(
    ConfigContext
  );

  const onRejectCookieNotice = () => {
    disableNonNecessaryCookies();
  };

  const onAcceptCookieNotice = (nonNecessaryCookiesEnabled = true) => {
    if (nonNecessaryCookiesEnabled) {
      enableNonNecessaryCookies();
      amplitude.getInstance().enableTracking();
    } else {
      disableNonNecessaryCookies();
    }
    setShowCookieModal(false);
  };

  const buttonWidth = {
    xxl: '190px',
    md: '150px',
    default: '156px'
  };

  return (
    <div
      className={`bg-3-5 color-8 sm:rounded text-sm w-90 p-16 fixed ${
        margin
      } z-50 md:left-282px bottom-0 sm:bottom-8 border-color-5_25 border`}
    >
      <div className="flex flex-col xl:flex-row gap-16 xl:gap-24">
        <div
          className="flex flex-col"
        >
          <p data-qa="cookieNotice-text">{i18nextTranslate(i18nextKeys.cookieNoticeDescription)}</p>
          <p
            data-qa="cookieNotice-button-settings"
            className="font-bold text-underline cursor-pointer mt-8 underline mb-8 xl:mb-0"
            onClick={() => setShowCookieModal(true)}
          >
            {i18nextTranslate(i18nextKeys.cookieNoticeSettings)}
          </p>
        </div>
        <div
          className="flex items-end justify-between gap-16"
        >
          <Button
            text={i18nextTranslate(i18nextKeys.buttonReject)}
            onClick={onRejectCookieNotice}
            width={buttonWidth}
            dataQa="cookieNotice-button-reject"
            secondary
          />
          <Button
            text={i18nextTranslate(i18nextKeys.buttonAccept)}
            onClick={onAcceptCookieNotice}
            width={buttonWidth}
            dataQa="cookieNotice-button-accept"
          />
        </div>
      </div>
      <CookieModal
        show={showCookieModal}
        close={() => setShowCookieModal(false)}
        onAccept={onAcceptCookieNotice}
      />
    </div>
  );
};

export default CookieNotice;
