import { BTC_OPTION, ETH_OPTION } from './WalletType';

export const BANK_TRANSFER = 'Bank';
export const BITCOIN = 'Bitcoin';
export const ETH = 'Eth';
export const PHYSICAL = 'Physical';
export const ERC20 = 'Erc20';

export const SCALE = {
  [BANK_TRANSFER]: 2,
  [BITCOIN]: 8,
  [ETH]: 18,
  [ERC20]: 18,
  [PHYSICAL]: 16 // default value
};

export const walletTypeForRedeemMethodType = (redeemMethodType) => {
  switch (redeemMethodType) {
    case BITCOIN: {
      return BTC_OPTION;
    }
    case ETH: {
      return ETH_OPTION;
    }
    case ERC20: {
      return ETH_OPTION;
    }
    default: {
      return null;
    }
  }
};

export const redemptionMethodTypes = [BANK_TRANSFER, BITCOIN, ETH, PHYSICAL, ERC20];
