import {
  combineValidators,
  composeValidators,
  createValidator
} from 'revalidate';
import {
  isArray,
  isJsonIf,
  isNonNegativeInteger,
  isRequiredString,
  isRequiredStringIf,
  isValueUnique,
  hasLengthGreaterThanIf
} from 'Utils/validators';
import i18nextTranslate from 'Lang/i18nextTranslate';
import i18nextTranslateDynamically from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { KYC_PROVIDER } from 'Enums/KycProvider';

const isArrayIf = (condition, field) => createValidator(
  () => (value) => {
    if (condition && !Array.isArray(value)) {
      return i18nextTranslateDynamically(i18nextKeys.helperValidatorArray, {
        field
      });
    }
  },
  () => {}
);

const sameProvider = createValidator(
  (message) => (tiers) => {
    const providers = tiers.reduce((result, { number, providers }) => {
      if (number && providers?.[0]?.name) {
        result.push(providers[0].name);
      }
      return result;
    }, []);
    const providersEqual = providers.every(name => name === providers[0]);
    if (!providersEqual) {
      return message;
    }
  },
  () => i18nextTranslate(i18nextKeys.validatorKycTiersDescriptionSingleProvider)
);

const functionalRestrictionValidator = () => ({
  purchase: isNonNegativeInteger('purchase'),
  redemption: isNonNegativeInteger('redemption')
});

const kycProviderValidator = (isKycEnabled) => {
  return (provider) => {
    return combineValidators({
      name: isRequiredStringIf('name', () => isKycEnabled),
      config: composeValidators(
        isRequiredStringIf('config', () => isKycEnabled),
        isJsonIf(isKycEnabled && provider.name !== KYC_PROVIDER.manual)
      )('config')
    })(provider);
  }
}

const providerArrayValidator =
  (isKycEnabled) => {
    return (field, providers = []) => {
      return providers.map(kycProviderValidator(isKycEnabled))
  };
}

const kycTierValidator = (isKycEnabled) => {
  return (kycTier) => {
    return combineValidators({
      number: isNonNegativeInteger('number'),
      name: isRequiredString('name'),
      description: isRequiredString('description'),
      providers: composeValidators(
        isArrayIf(kycTier.number > 0, 'providers'),
        hasLengthGreaterThanIf(0, kycTier.number > 0 && isKycEnabled,
          i18nextKeys.validatorKycTiersDescriptionProviderRequired)('providers'),
        providerArrayValidator(isKycEnabled)
      )('providers')
    })(kycTier);
  }
}

const kycTiersArrayValidator = (isKycEnabled) => {
  return (field, tiers) => {
    return tiers.map(kycTierValidator(isKycEnabled))
  }
}

export const kycTiersDescriptionValidator = (fieldName, isKycEnabled) =>
  composeValidators(
    isArray,
    hasLengthGreaterThanIf(0, isKycEnabled,
      i18nextKeys.validatorKycTiersDescriptionTierRequired)('kycTiersDescription'),
    isValueUnique(
      i18nextTranslate(i18nextKeys.validatorKycTiersDescriptionUniqueTierNumber),
      'number'
    ),
    sameProvider,
    kycTiersArrayValidator(isKycEnabled)
  )(fieldName);

export const kycTierFunctionalRestrictionsValidator = () => ({
  visibility: functionalRestrictionValidator(),
  availability: functionalRestrictionValidator()
});
