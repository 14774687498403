import React, { useCallback } from "react";
import { Form as FinalForm, Field as FinalField } from "react-final-form";
import AnimateHeight from "react-animate-height";
import Text from "Components/shared/Text";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import useConfigSettings from 'Hooks/useConfigSettings';
import Toggle from "Components/shared/Toggle";
import { Button } from "Components/shared/buttons";
import { FormField } from "Components/shared/formElements";
import { SubsectionHeading } from "../shared";

const EmailNotifications = () => {
  const {
    data: {
      NotificationEnabled,
      NotificationEmails
    },
    isLoading: loadingData
  } = useConfigSettings.query({
    select: useCallback(({ NotificationEnabled, NotificationEmails }) => ({ NotificationEnabled, NotificationEmails }), [])
  });

  const {
    isLoading: updatingSettings,
    mutate: updateSettings
  } = useConfigSettings.mutation({});

  const validate = (value) => {
    if (!value) {
      return;
    }
    const values = value.split(";");
    for (const email of values) {
      if (email && !email.match(/^(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)$/gi)) {
        return i18nextTranslate(i18nextKeys.tenantSettingsEmailNotificationsError, { email });
      }
    }
  };

  return (
    <>
      <Text
        textStyle="h2"
        className="mb-24 xxl:mb-32"
        dataQa="emailNotifications-heading"
        uppercase
      >
        {i18nextTranslate(i18nextKeys.tenantSettingsEmailNotificationsHeading)}
      </Text>
      <SubsectionHeading
        text={i18nextTranslate(i18nextKeys.tenantSettingsEmailNotificationsSubheading)}
        tooltipText={i18nextTranslate(i18nextKeys.tenantSettingsEmailNotificationsTooltip)}
        dataQa="emailNotifications"
      />
      <div className="ml-96 mt-32">
        <Toggle
          toggled={NotificationEnabled}
          onToggle={(state) => updateSettings({
            data: {
              NotificationEnabled: state
            }
          })}
          disabled={loadingData || updatingSettings}
        />
      </div>
      <AnimateHeight
        duration={750}
        height={NotificationEnabled ? "auto" : 0}
      >
        <FinalForm
          initialValues={{ emails: NotificationEmails.join(";") }}
          onSubmit={({ emails }) => updateSettings({
            data: {
              NotificationEmails: emails ? emails.split(";").filter(email => email) : []
            }
          })}
          subscription={{
            invalid: true,
            pristine: true
          }}
        >
          {({ handleSubmit, invalid, pristine }) => (
            <form
              onSubmit={handleSubmit}
              className="flex gap-10 items-start flex-wrap mt-32"
            >
              <FinalField
                style={{ width: "350px", maxWidth: "100%" }}
                name="emails"
                dataQa="emailNotifications"
                disabled={loadingData || updatingSettings}
                subscription={{
                  active: true,
                  error: true,
                  touched: true,
                  value: true
                }}
                validate={validate}
                validateFields={[]}
                component={FormField}
              />
              <Button
                type="submit"
                text={i18nextTranslate(i18nextKeys.buttonSave)}
                width={{ default: "140px" }}
                disabled={loadingData || updatingSettings || invalid || pristine}
              />
            </form>
          )}
        </FinalForm>
      </AnimateHeight>
    </>
  );
};

export default EmailNotifications;
