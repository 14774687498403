import React, { useContext, useEffect, useState } from 'react';
import {
  Form as FinalForm,
  Field as FinalField
} from 'react-final-form';
import { useAmplitude } from 'react-amplitude-hooks';
import api from 'Api';
import { REFERRAL_CODE_STATE } from 'Enums';
import usePostLoginRedirect from 'Hooks/usePostLoginRedirect';
import { AuthContext } from 'States/auth/authState';
import { ConfigContext } from 'States/config/configState';
import { LangContext } from 'States/lang/langState';
import { UiContext } from 'States/ui/uiState';
import { Button } from 'Components/shared/buttons';
import { FormField } from 'Components/shared/formElements';
import SimplePage from 'Components/shared/SimplePage';
import { HandIcon } from 'Components/shared/symbols';
import { isRequiredIfWithMessage } from 'Utils/validators';
import useHandleError from 'Utils/handleError';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';

const Signup = () => {
  const { user, getStatus } = useContext(AuthContext);
  const {
    config: {
      checkoutSettings,
      fullNameWelcome
    }
  } = useContext(ConfigContext);
  const { lang } = useContext(LangContext);
  const {
    breakpoints: { md, xxl }
  } = useContext(UiContext);
  const handleError = useHandleError();
  const redirect = usePostLoginRedirect();
  const { logEvent } = useAmplitude();
  const [loading, setLoading] = useState(true);
  const [referralRequired, setReferralRequired] = useState(false);

  useEffect(() => {
    const getRegistrationTemplateSettings = async () => {
      try {
        const { ReferralCodeState } = await api.User.getRegistrationTemplate();
        setReferralRequired(ReferralCodeState === REFERRAL_CODE_STATE.Required);
        setLoading(false);
      } catch (error) {
        handleError({ error });
      }
    }
    getRegistrationTemplateSettings();
  }, []);

  const signUp = async ({ ReferralCode }) => {
    logEvent('Registration initiated', {
      "referral needed": referralRequired,
      "referral provided": ReferralCode ? "entered" : "blank"
    });
    const payload = {
      Language: lang,
      ReferralCode
    };
    try {
      await api.User.signUp(payload);
      const isEmailConfirmed = await api.User.getEmailConfirmationStatus();
      const status = await getStatus();
      redirect({
        status,
        isEmailConfirmed,
        hideShop: checkoutSettings.hideShop
      });
    } catch (error) {
      const message = i18nextTranslate(i18nextKeys.errorSignup);
      handleError({ error, message });
    }
  };

  const buttonWidth = {
    xxl: '190px',
    default: '170px'
  };

  return (
    <SimplePage
      header={i18nextTranslate(i18nextKeys.signupTitle)}
      icon={HandIcon}
      title={i18nextTranslateDynamically(
        i18nextKeys.signupCodeTitle,
        { name: `${user.profile.given_name}${fullNameWelcome ? " " + user.profile.family_name : ""}` }
      )}
      description={i18nextTranslate(i18nextKeys.signupCodeDescription)}
      loading={loading}
      dataQa="signup"
    >
      <FinalForm
        onSubmit={signUp}
        subscription={{
          invalid: true,
          submitting: true
        }}
      >
        {({ handleSubmit, invalid, submitting }) => (
          <form
            className="w-full flex flex-col items-center gap-24"
            onSubmit={handleSubmit}
          >
            <FinalField
              name="ReferralCode"
              subscription={{
                active: true,
                error: true,
                touched: true,
                value: true
              }}
              validate={isRequiredIfWithMessage(
                () => referralRequired,
                i18nextTranslate(i18nextKeys.signupCodeLabel)
              )}
            >
              {(props) => (
                <FormField
                  label={i18nextTranslate(i18nextKeys.signupCodeLabel)}
                  placeholder={i18nextTranslate(i18nextKeys.signupCodePlaceholder)}
                  className="px-8 md:px-0"
                  style={{
                    width: xxl ? "484px" : md ? "404px" : "100%"
                  }}
                  disabled={submitting}
                  required={referralRequired}
                  {...props}
                />
              )}
            </FinalField>
            <Button
              type="submit"
              dataQa="signup-button"
              text={i18nextTranslate(i18nextKeys.buttonContinue)}
              width={buttonWidth}
              disabled={invalid}
              loading={submitting}
            />
          </form>
        )}
      </FinalForm>
    </SimplePage>
  );
};

export default Signup;
