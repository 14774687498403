import React, { useContext, useMemo } from "react";
import { LogOnMount } from "react-amplitude-hooks";
import { useHistory, useLocation } from "react-router-dom";
import { getTranslation } from 'Helpers/translations';
import linkify from 'Helpers/linkify';
import { useFormatNumber } from 'Utils/formatNumber';
import { TileInfoCard } from "Components/shared/Tile";
import i18nextTranslate from "Lang/i18nextTranslate";
import { i18nextKeys } from "Lang/i18nextKeys";
import useConfigSettings from "Hooks/useConfigSettings";
import Text from "Components/shared/Text";
import { Button } from "Components/shared/buttons";
import i18nextTranslateDynamically from "Lang/i18nextTranslateDynamically";
import { ROUTES } from "Router/Routes";
import { AuthContext } from "States/auth/authState";
import { ConfigContext } from "States/config/configState";
import { LangContext } from 'States/lang/langState';
import { UiContext } from "States/ui/uiState";
import { Tile, HorizontalRule } from "Components/shared/Tile";
import {
  BANK_TRANSFER,
  BITCOIN,
  ETH,
  ERC20,
  PHYSICAL,
} from 'Enums/RedemptionMethodTypes';
import BankAccountSummary from "Components/BankAccount/Summary/BankAccountSummary";
import WalletSummary from "Components/CryptoWallet/WalletSummary";
import { PhysicalPayoutDetails } from "Features/admin/RedeemManagement/Details/RedemptionPayoutDetails";

const RedeemFinish = () => {
  const location = useLocation();
  const history = useHistory();
  const formatNumber = useFormatNumber();
  const { isAdmin } = useContext(AuthContext);
  const { lang } = useContext(LangContext);

  const {
    config: {
      imprint,
      redeemSettings: {
        additionalInfoRedemptionRequest
      }
    }
  } = useContext(ConfigContext);

  const {
    breakpoints: { xxl }
  } = useContext(UiContext);

  const {
    data: {
      defaultLanguageCode,
      restrictedMode
    }
  } = useConfigSettings.query({
    refetchOnMount: false,
    select: ({ Languages, RestrictedMode }) => {
        const defaultLanguage = Languages.find(({ IsDefault }) => IsDefault);
        return {
          defaultLanguageCode: defaultLanguage?.Code,
          restrictedMode: RestrictedMode && !isAdmin
      };
    }
  });

  const translatedAssetName = useMemo(() => getTranslation(
    "Name", lang, defaultLanguageCode, location.state?.redemptionMethod.UserPays.Translations
  ) || location.state?.redemptionMethod.UserPays.Name, [lang, defaultLanguageCode]);

  const redemptionMethodName = useMemo(() => getTranslation(
    "Name", lang, defaultLanguageCode, location.state?.redemptionMethod.Translations
  ), [lang, defaultLanguageCode])

  const RedemptionMethodSummary = () => {
    switch (location.state?.redemptionMethod?.Type) {
      case BANK_TRANSFER:
        return (
          <BankAccountSummary
            bankAccount={location.state.redemptionForm?.bankAccount}
          />
        );
      case BITCOIN:
      case ETH:
      case ERC20:
        return (
          <WalletSummary
            wallet={location?.state?.redemptionForm.wallet}
          />
        );
      default:
        return null;
    }
  };

  const toRedemptionsList = () => history.push(ROUTES.redemptions);
  const toHomePage = () => history.push(ROUTES.base)

  const buttonWidth = {
    xxl: '190px',
    md: '170px',
    default: '156px'
  };

  return (
    <LogOnMount eventType="Invoice paid">
      <div
        data-qa="redeem-finish"
        className="flex flex-col gap-16 md:gap-24 cursor-default color-8"
      >
        <Tile>
          <div className="flex flex-col gap-32">
            <div className="flex flex-col gap-16">
              <Text
                textStyle="h1"
                color="color-4"
                dataQa="title"
              >
                {i18nextTranslate(i18nextKeys.redeemFinishTitle)}
              </Text>
              <Text
                textStyle="p2"
                dataQa="subtitle"
                dangerouslySetContent
              >
                {i18nextTranslate(i18nextKeys.redeemFinishSubtitle)}
              </Text>
              <Text
                textStyle="p4"
                dataQa="description"
                dangerouslySetContent
              >
                {linkify(i18nextTranslateDynamically(i18nextKeys.redeemFinishDescription, {
                  userEmail: location.state?.userEmail || '',
                  supporterEmail: imprint?.email
                }))}
              </Text>
            </div>
            <div className="flex flex-col md:flex-row gap-24">
              <TileInfoCard
                title={i18nextTranslate(i18nextKeys.redemptionMethod)}
                text={redemptionMethodName}
                dataQa="method"
              />
              <TileInfoCard
                title={i18nextTranslate(i18nextKeys.redeemFinishRedemptionReferenceCode)}
                text={location.state?.redemption?.ReferenceCode}
                dataQa="reference"
              />
            </div>
          </div>
        </Tile>

        <Tile
          title={i18nextTranslate(i18nextKeys.redeemFinishPaymentDetails)}
          dataQa="payment"
        >
          <div className="flex flex-col md:flex-row gap-24">
            <TileInfoCard
              title={i18nextTranslate(i18nextKeys.redeemFinishPaymentGross)}
              text={`${formatNumber(location.state?.redemption?.GrossAmount.AssetUnitsString)
                } ${location.state?.redemptionMethod?.UserPays?.UnitOfMeasureCode ||
                location.state?.redemptionMethod?.UserPays?.CurrencyCode
                }`}
              width={xxl ? "260px" : "210px"}
              dataQa="pay-gross"
            />
            <TileInfoCard
              title={i18nextTranslate(i18nextKeys.redeemFinishPaymentNet)}
              text={`${formatNumber(location.state?.redemption?.NetAmount.AssetUnitsString)
                } ${location.state?.redemptionMethod?.UserPays?.UnitOfMeasureCode ||
                location.state?.redemptionMethod?.UserPays?.CurrencyCode
                }`}
              width={xxl ? "260px" : "210px"}
              dataQa="pay-net"
            />
            <TileInfoCard
              title={i18nextTranslate(i18nextKeys.redeemFinishPaymentAsset)}
              text={translatedAssetName}
              width={xxl ? "260px" : "210px"}
              dataQa="pay-asset"
            />
          </div>
          {!!location.state?.redemptionForm?.AdditionalDetails && (
            <>
              <HorizontalRule className="my-24" />
              <TileInfoCard
                title={additionalInfoRedemptionRequest.name}
                text={location.state?.redemptionForm?.AdditionalDetails}
                dataQa="additional-details"
              />
            </>
          )}
        </Tile>

        {location.state?.redemptionMethod?.Type === PHYSICAL ? (
          <PhysicalPayoutDetails
            redemptionRequest={{
              ...location?.state?.redemption,
              PayoutMethodData: {
                BeneficiaryAddress: location?.state?.redemptionForm.beneficiary
              }
            }}
          />
        ) : (
          <Tile
            title={i18nextTranslate(i18nextKeys.redeemFinishPayoutDetails)}
            dataQa="payout"
          >
            <div className="flex flex-col gap-24 xl:gap-32">
              <TileInfoCard
                title={i18nextTranslate(i18nextKeys.redeemFinishAmountReceive)}
                text={`${formatNumber(location.state?.redemption?.NetAmount.PayoutUnitsString)
                  } ${location.state?.redemptionMethod?.UserGets?.Uom}`}
                dataQa="receive"
              />
              <HorizontalRule />
              <RedemptionMethodSummary />
            </div>
          </Tile>
        )}

        <div className="flex justify-between items-center">
          {!restrictedMode && (
            <Button
              dataQa="button-home"
              text={i18nextTranslate(i18nextKeys.buttonHome)}
              width={buttonWidth}
              onClick={toHomePage}
              tertiary
            />
          )}
          <Button
            dataQa="button-redemptions"
            text={i18nextTranslate(i18nextKeys.redemptionFinishButton)}
            width={buttonWidth}
            onClick={toRedemptionsList}
            secondary
          />
        </div>
      </div>
    </LogOnMount>
  );
};

export default RedeemFinish;
